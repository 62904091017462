/* header */
.header {
  display: block;
  max-width: 94%;
  padding: 40px 10px 10px;
  margin: 0px auto;
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}

.grey-color {
  color: #868e96;
}

.header li a {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
}

.header .logo {
  display: block;
  float: left;
  font-size: 1.5em;
  text-decoration: none;
  line-height: normal;
}

.header .logo-name {
  font-family: "Google Sans Regular";
  font-weight: bolder;
  padding: 0 10px;
  letter-spacing: 3px;
}

/* menu */
.menu {
  font-family: "Google Sans Regular";
}

.header .menu {
  clear: both;
  transition: max-height 0.2s ease-out;
}

/* menu icon */
.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* nav btn */
.nav-btn {
  transition: ease-out 0.6s;
  margin: 0 2px 4px;
}

.nav-btn:hover {
  transition: ease-in 0.6s;
}

/* menu btn */
.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 370px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@media (min-width: 54em) {
  .header li {
    float: left;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header .menu {
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }
}
@media (max-width: 960px) and (min-width: 864px) {
  .header {
    font-size: 12px;
  }
}

@media (max-width: 54em) {
  .header .menu {
    clear: both;
    margin-top: 24px;
    padding-top: 24px;
    flex-wrap: wrap;
    display: flex;
  }
}
