.themetoggle-button {
  cursor: pointer;
  height: 45px;
  width: 45px;
  margin-right: 5px;
  margin-left: 15px;
  padding-top: 5px;
  border-radius: 50%;
  font-size: 20pt;
  border: none;
  align-items: center;
  justify-content: center;
  outline: none;
  transition: all 0.2s ease-in-out;
}
